import React, { useRef } from "react";
import * as PR from "../../prime-modules/index";
import { eSIMLogo } from "../../assets/images/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { verifyemail } from "../../services/api";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');

  const formInitialValues = {
    otp: '',
  };

  const validationSchema = () => {
    return Yup.object().shape({
      otp: Yup.string().trim()
        .required('OTP is required')
        .min(6, 'OTP must be 6 numbers')
        .max(6, 'OTP must be 6 numbers')
        .matches(/^[0-9\b]+$/, 'Characters not allowed')
    });
  }

  const handleSubmit = (formData) => {
    if (!userId) {
        toast.current.show({ life: 10000, severity: 'error', summary:"Error", detail: "User ID is missing" })
    } else {
        const verifyRequest = {
            userId: +userId,
            code: formData.otp.trim()
        };
        const getVerifyResponse = (response) => {
          if (response.result === "SUCCESS") {
            formik.resetForm();
            toast.current.show({ life: 10000, severity: 'success', summary: 'Success', detail: 'Your email address has been verified successfully.' });
            setTimeout(() => {
              navigate('/login');
            }, 2000);
          } else if (response.result === "FAILED" && response.error) {
            const error = response.error;
            toast.current.show({
              severity: error.severity,
              summary: "Error",
              detail: error.errorMsg ? error.errorMsg : error.summary,
            });
          }
        };
        verifyemail(verifyRequest, dispatch, getVerifyResponse);
    }
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <section className="admin-login-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
            <div className="login-wrapper">
            <div className="login-header flex align-items-center justify-content-between">
                <PR.Image
                  src={eSIMLogo}
                  alt="Logo"
                  className="logo-img"
                />
                <h1>Affiliates Email Verification</h1>
              </div>
              <form autoComplete="off" onSubmit={formik.handleSubmit} className="email-verification-form">
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <h3>Verify your email address</h3>
                    <h5>Enter 6 digit verification code sent to your registered email address</h5>
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-envelope" />
                      <PR.InputText
                        id="otp"
                        name="otp"
                        placeholder="OTP"
                        className="w-12"
                        value={formik.values.otp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>
                    {formik.errors.otp && formik.touched.otp ? (
                      <div className="error-message">
                        {formik.errors.otp}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 mt-3 navigate">
                    <PR.Button
                      label="Submit"
                      type="submit"
                      className="login-btn w-12"
                    />
                  </div>
                  <div className="col-12 text-center">
                    <p className="login-p">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default VerifyEmail;

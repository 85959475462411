import { createSlice } from '@reduxjs/toolkit';

const affiliateSessionData = localStorage.getItem('affiliateSessionData') !== null ? JSON.parse(localStorage.getItem('affiliateSessionData')) : {};
const affiliateLoggedIn = localStorage.getItem('affiliateSessionData') !== null ? true : false;

const initialAuthState = {
    affiliateLoggedIn: affiliateLoggedIn,
    affiliateSessionData: affiliateSessionData,
};

const affiliateAuthSlice = createSlice({
    name: 'affiliateAuthentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const storedLoginInfo = {
                sessionId: action.payload.sessionId,
                affiliateName: action.payload.firstName + ' ' + action.payload.lastName,
            }
            state.affiliateLoggedIn = true;
            state.affiliateSessionData = storedLoginInfo;
            localStorage.setItem('affiliateSessionData', JSON.stringify(storedLoginInfo));
        },
        onLogout(state) {
            state.affiliateLoggedIn = false;
            state.affiliateSessionData = {};
            localStorage.removeItem('affiliateSessionData');
        },
    },
});

export const affiliateAuthActions = affiliateAuthSlice.actions;

export default affiliateAuthSlice.reducer;
export const globalConfig = {
     defaultCurrency: '$',
     pageCount: 20,
     searchPlaceholder:'Search..',
     pagination_Previous:'Prev',
     pagination_Next:'Next',
     allowPhoneNumberLength: 16,
     allowNameLength: 20
}

export const envConfig = process.env;
import React, { useState } from 'react';
import "../login/Login.scss";
import { envConfig } from "../../utils/settings";

const AffiliateFooter = () => {
  const mainBaseUrl = envConfig.REACT_APP_MAIN_URL;
  const [year] = useState(new Date().getFullYear())
  return (
    <>
        <footer className='affiliate-footer'>
            <p>&copy; {year} <a href={mainBaseUrl} target="_blank" rel="noreferrer">eSimple</a> | Designed by <a href="https://bluehouse-technology.co.uk" target="_blank" rel="noreferrer">Bluehouse Technology</a></p>
        </footer>
    </>
  )
}
export default AffiliateFooter;
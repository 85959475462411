import axios from "axios";
import { affiliateAuthActions } from "../store/affiliateAuth";

const baseUrl = '/v1/';
const errorHandler = (err,dispatch) => {
    let errorMessage = {};
    switch (err.response && err.response.status) {
        case 401:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response.statusText;
            errorMessage.errorMsg = err.response.data.errorMsg;
            dispatch(affiliateAuthActions.onLogout());
            break;
        case 400:
        case 403:
        case 500:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response.statusText;
            errorMessage.errorMsg = err.response.data.errorMsg;
            break;
        default:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response.statusText;
            errorMessage.errorMsg = err.response.data.errorMsg;
            break;
    }
    return errorMessage;
}

export const login = (data, dispatch, callback) => {
    axios.post(baseUrl + 'login', data)
        .then(response => {
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data });
            } else {
                callback({ result: "FAILED", data: 'Failed' });
            }
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const signup = (data, dispatch, callback) => {
    axios.post(baseUrl + 'signup', data)
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data })
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage })
        })
}

export const verifyemail = (data, dispatch, callback) => {
    axios.post(baseUrl + 'verifyemail', data)
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data })
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage })
        })
}

export const getOrders = (headers, dispatch, obj, callback) => {
    const params = '?listingType='+obj.listingType+'&offset='+ obj.offset + '&limit=' + obj.limit+ '&orderBy=' + obj.sortField+'&direction=' + obj.sortOrder+ '&orderStatus=' + obj.orderStatus+'&purchaseDateFrom=' + obj.purchaseDateFrom+'&purchaseDateTo='+obj.purchaseDateTo;
     axios.get(baseUrl + 'orders'+params, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

